import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMAGES } from "src/shared/assets/images";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useReportCreditUnsubscribeMutation } from "../../../store/services/privateApi";
import { navigate } from "@storybook/addon-links";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../../components";
import { useState } from "react";

interface Props {
  handleCloseEvent: VoidFunction;
}

export const OpOut = ({ handleCloseEvent }: Props) => {
  const { t } = useTranslation();
  const [mutation] = useReportCreditUnsubscribeMutation();
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open
      onClose={handleCloseEvent}
      PaperProps={{
        sx: {
          margin: 4,
          width: "calc(100% - 32px)",
        },
      }}
    >
      <DialogContent sx={{ px: 4, py: 6, textAlign: "center" }}>
        <Stack gap={2}>
          <Typography variant="h6" color="gray.700">
            Opt Out
          </Typography>
          <Typography color="gray.700" sx={{ fontWeight: 300 }}>
            We are sorry that you want to opt out of Rent Reporting.
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              component="img"
              src={IMAGES.CREDIT_BUILDER}
              alt="credit builder"
              width="239px"
            />
          </Box>
          <Typography textAlign={"left"}>
            There are many benefits of Rent Reporting -
          </Typography>
          <Typography
            color="gray.700"
            sx={{
              textAlign: "left",
              fontWeight: 300,
              lineHeight: "20px",
              "& a": { color: "gray.700", textDecoration: "underline" },
            }}
          >
            <List
              sx={{
                listStyleType: "disc",
                pl: 4, // padding-left
                "& .MuiListItem-root": {
                  display: "list-item",
                },
              }}
            >
              <ListItem>
                <ListItemText primary="Can significantly boost credit scores, with average increases of nearly 60 points" />
              </ListItem>
              <ListItem>
                <ListItemText primary="Better credit scores help qualify for loans, credit cards, and other financial products" />
              </ListItem>
              <ListItem>
                <ListItemText primary="May lead to better terms and rates on financial products, saving money long-term" />
              </ListItem>
            </List>
          </Typography>
          <Button variant="contained" onClick={handleCloseEvent}>
            Keep My Subscription
          </Button>
          <Link
            component="button"
            fontSize={12}
            onClick={async () => {
              setConfirmed(true);
            }}
          >
            No Thanks,I still want to Opt Out
          </Link>
        </Stack>
      </DialogContent>
      {confirmed && (
        <ConfirmationModal
          title="Confirm Opt Out"
          content=" You will no longer receive the benefits of Rent Reporting. Any opt out after 25th  will still be charged for that month."
          btnTitle={t("buttonTexts.okButton")}
          isYesNoConfirmationModal
          onFormSubmit={async () => {
            const res: any = await mutation({});
            if (!res.error) {
              navigate("/settings");
            }
          }}
          onClickCancelEvent={() => {
            setConfirmed(false);
          }}
        />
      )}
    </Dialog>
  );
};
