import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  CreditScoreCard,
  DataGridCard,
  DivAuthorizedWrapper,
  GridEmptyCard,
  Header,
  HeaderFixed,
  ManualLoader,
  NotificationMessage,
  ScoreProgressChartCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import {
  set_old_date_for_rent_reporting_history,
  sortingArrayByDateForRentStatement,
} from "src/helpers/helperRent";
import {
  creditScroreBgColor,
  creditScroreIntervals,
} from "src/shared/assets/data";
import { theme } from "src/shared/theme/theme";
import { todayDateYYYYMMDD } from "src/shared/utilities/dateConvertions";
import {
  useGetCreditScoreQuery,
  useGetReportingStatementsQuery,
  useGetUserQuery,
} from "src/store/services/privateApi";
import { ViewMyCreditScoreModal } from "src/components/Modals/ViewMyCreditScoreModal";
import { Link } from "@mui/material";
import { OpOut } from "./OptOut";

export const RentReportingHomeScreenContainer = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [gridData, setGridData] = useState<any[]>([]);
  const [viewCreditScoreModal, setViewCreditScoreModal] = useState(false);

  const [viewOptOut, setViewOptOut] = useState(false);

  // get the user api details ___
  const {
    data: userData,
    isSuccess: isLoadingUserDataSuccess,
    isLoading: isLoadingUserData,
  } = useGetUserQuery(null);

  // check is credit reporting is enabled or not ___
  const isCreditReportingReady =
    isLoadingUserDataSuccess &&
    userData &&
    userData?.user?.isCreditReportingReady;

  // get the transaction list via api ___
  const {
    data: rentReportingData,
    isSuccess: isSuccessRentReportingData,
    isLoading: isLoadingRentReportingData,
  }: any = useGetReportingStatementsQuery({
    startDate: set_old_date_for_rent_reporting_history,
    endDate: todayDateYYYYMMDD(),
  });

  // get credit score
  const {
    data: creditScoreData,
    isSuccess: isSuccessCreditScoreData,
    isLoading: isLoadingCreditScoreData,
  } = useGetCreditScoreQuery(null);

  const handleCloseCreditScoreModal = () => {
    setViewCreditScoreModal(false);
    localStorage.setItem("visitRentReporting", "true");
  };

  useEffect(() => {
    const visitRentReporting = localStorage.getItem("visitRentReporting");
    setViewCreditScoreModal(visitRentReporting !== "true");
  }, []);

  useEffect(() => {
    if (
      isSuccessRentReportingData &&
      rentReportingData &&
      rentReportingData.length > 0
    ) {
      const sortedArray = sortingArrayByDateForRentStatement(
        rentReportingData,
        true
      );
      setGridData(sortedArray);
    }
  }, [isSuccessRentReportingData, rentReportingData]);

  return (
    <>
      {isLoadingUserData && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonVisible
          paddingX={0}
          headerText={t("rentReporting.header")}
          withCenteredHeaderText
          onBackButtonClick={() => navigate("/rent")}
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <DivAuthorizedWrapper
            sx={{
              [`@media(min-width: ${theme.breakpoints.values.sm}px)`]: {
                marginBottom: 4,
              },
              [`@media(max-width: ${theme.breakpoints.values.sm}px)`]: {
                paddingBottom: 0,
              },
            }}
          >
            {!isCreditReportingReady && (
              <NotificationMessage
                messageHeading={t("rentReporting.header")}
                messageContent={t("rentReporting.notReadyMessageContent")}
                messageLinkEvent={() =>
                  navigate("/settings", {
                    state: { isFromRentReportingScreen: true },
                  })
                }
                variant="rent_reporting_disabled"
                topMargin={0}
              />
            )}
            <CreditScoreCard
              creditScroreBgColor={creditScroreBgColor}
              creditScroreIntervals={creditScroreIntervals}
              isLoadingFadeEnabled={isLoadingCreditScoreData}
              isSuccessLoadData={isSuccessCreditScoreData}
              statmentData={creditScoreData}
            />
            <ScoreProgressChartCard
              isSuccessLoadData={isSuccessCreditScoreData}
              creditScoreData={creditScoreData}
              isLoadingFadeEnabled={isLoadingCreditScoreData}
            />

            {
              /**
               * display DataGridCard component with transaction data
               * If transaction data length is more than 0 &&  api data loading is done
               */
              isSuccessRentReportingData &&
                rentReportingData &&
                rentReportingData?.length > 0 &&
                !isLoadingRentReportingData && (
                  <DataGridCard
                    t={t}
                    dataObject={gridData}
                    dataGridCardTitle={t(
                      "rentReporting.reportingHistory.title"
                    )}
                    isRecentWorkOrders
                    isCardLinkAvailable
                    navigate={navigate}
                    isTooltipInfoEnabled
                    tooltipContent={t(
                      "rentReporting.reportingHistory.tooltipContent"
                    )}
                    gridType="reporting_history"
                    viewAllLink="/rent/reporting/list"
                    routeState={{ backRouteUrl: "/rent/reporting/home" }}
                    iconArrowVisible={false}
                    isHandleArrowClickable={false}
                  />
                )
            }

            {
              /**
               * display GridEmptyCard component for empty transaction data with empty state content
               * this component will display after api data loading is done
               */
              isSuccessRentReportingData &&
                rentReportingData &&
                rentReportingData?.length === 0 &&
                !isLoadingRentReportingData && (
                  <GridEmptyCard
                    title={t("rentReporting.reportingHistory.title")}
                    type="reporting_history"
                    isTooltipInfoEnabled
                    tooltipContent={t(
                      "rentReporting.reportingHistory.tooltipContent"
                    )}
                    emptyStateContent={t(
                      "rentReporting.reportingHistory.emptyStateContent"
                    )}
                    isButtonVisible={false}
                  />
                )
            }

            {
              /**
               * display GridEmptyCard component without empty state content
               * this component will display while api data loading
               */
              isLoadingRentReportingData && !isSuccessRentReportingData && (
                <GridEmptyCard
                  title={t("rentReporting.reportingHistory.title")}
                  type="reporting_history"
                  isTooltipInfoEnabled={true}
                  tooltipContent={t(
                    "rentReporting.reportingHistory.listTooltipContent"
                  )}
                  isEmptyCardDataVisble={false}
                  isLoadingFadeEnabled={isLoadingUserData}
                  isInnerLoaderEnabled={
                    !isLoadingUserData && isLoadingRentReportingData
                  }
                />
              )
            }
          </DivAuthorizedWrapper>
          {isCreditReportingReady && (
            <Link
              component="button"
              onClick={() => {
                setViewOptOut(true);
              }}
            >
              I want to Opt Out of Credbuild
            </Link>
          )}
          <br></br>
        </WrpperAuthorizedContainer>
      </Wrapper>
      {viewCreditScoreModal && (
        <ViewMyCreditScoreModal
          handleCloseEvent={handleCloseCreditScoreModal}
        />
      )}
      {viewOptOut && (
        <OpOut
          handleCloseEvent={() => {
            setViewOptOut(false);
          }}
        />
      )}
    </>
  );
};
