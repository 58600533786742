import {
  ButtonAuthorizedWrapper,
  ConfirmationModal,
  FormAuthorizedWrapper,
  FormTextArea,
  FromAutoCompleteSelect,
  Header,
  HeaderFixed,
  LanguageSwitcher,
  ManualLoader,
  Messages,
  ShadowCard,
  Wrapper,
  WrpperAuthorizedContainer,
} from "src/components";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ERROR_MESSAGES } from "../../shared/utilities/validationMessages";
import { updateBindingsChange } from "../../shared/utilities/formUtilities";
import { Box, Button, Link, Typography } from "@mui/material";
import { usePostTicketMutation } from "../../store/services/privateApi";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Anchor } from "@mui/icons-material";

export const SettingsQuestionScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string>("");
  const [mutation, { isLoading, error }] = usePostTicketMutation();

  const handleBackButton = () => {
    navigate("/settings");
  };
  const {
    register,
    handleSubmit,
    setValue,
    // getValues,
    control,
    trigger,
    formState: { errors },
    watch,
  } = useForm<{
    message: string;
    subject: string;
  }>({
    resolver: yupResolver(
      yup.object({
        subject: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
        message: yup
          .string()
          .typeError(ERROR_MESSAGES.REQUIRED)
          .required(ERROR_MESSAGES.REQUIRED),
      })
    ),
  });
  const formSubmit = handleSubmit(async (data) => {
    const response: any = await mutation(data);
    if (response?.data) {
      setConfirmationModalOpen(true);
    } else {
      setErrorMessage(
        response?.error?.data?.error ?? response?.error?.data?.message
      );
    }
  });
  return (
    <>
      {isLoading && <ManualLoader />}
      <HeaderFixed className="fixed_only_desktop">
        <Header
          isMainMunuVisible
          isBackButtonHide
          onBackButtonClick={handleBackButton}
          isBackButtonVisible
          paddingX={0}
          headerText={"Ask A Question"}
          withCenteredHeaderText
        />
      </HeaderFixed>
      <Wrapper isAuthorizedWrapper idSelector={"wrapperContainer"}>
        <WrpperAuthorizedContainer>
          <FormAuthorizedWrapper onSubmit={formSubmit}>
            <ShadowCard padding={4} marginBottom={6}>
              <Typography color="gray.800">
                <List sx={{ listStyleType: "disc", pl: 4, px: 1, py: 1 }}>
                  <ListItem sx={{ display: "list-item", px: 1, py: 1 }}>
                    <ListItemText primary="Please keep queries relevant to Tenantport" />
                  </ListItem>
                  <ListItem sx={{ display: "list-item", px: 1, py: 1 }}>
                    <ListItemText
                      primary={
                        <Link
                          href="/requests/maintenance/create"
                          underline="hover"
                        >
                          For maintenance requests, click here
                        </Link>
                      }
                    />
                  </ListItem>
                </List>
              </Typography>
              <Controller
                control={control}
                name="subject"
                render={({ field: { onChange, value } }) => {
                  return (
                    <FromAutoCompleteSelect
                      label={"Topic"}
                      id={"subject"}
                      {...register("subject")}
                      error={errors?.subject?.message}
                      onChange={(_event: any, selectedOption: any) => {
                        if (selectedOption) {
                          onChange(selectedOption.label);
                        }
                      }}
                      inputValue={value || ""}
                      options={[
                        {
                          label: "Payments",
                        },
                        {
                          label: "Credbuild - Opt Out",
                        },
                        {
                          label: "Credbuild - Opt In",
                        },
                        {
                          label: "Autopay",
                        },
                        {
                          label: "Other",
                        },
                      ]}
                      type="shadowed"
                    />
                  );
                }}
              />
              {watch("subject") === "Credbuild - Opt Out" && (
                <Typography fontWeight={"bold"} fontStyle={"italic"}>
                  Please use this option only if you are unable to use
                  Account/Rent Reporting/Opt Out
                </Typography>
              )}
              <Controller
                control={control}
                name="message"
                render={({ field: { value } }) => {
                  return (
                    <FormTextArea
                      label={t("formInput.description")}
                      id={"description"}
                      {...register("message")}
                      placeholder={t("placeHolder.description")}
                      error={errors?.message?.message}
                      onChange={(event: any) => {
                        updateBindingsChange(event, "message", setValue);
                      }}
                      inputFocus={(e: any) => {
                        trigger("message");
                      }}
                      value={value}
                    />
                  );
                }}
              />
              {errorMessage !== "" && (
                <Messages
                  messageHeading={"Error"}
                  messageContent={errorMessage}
                  closeEvent={() => setErrorMessage("")}
                  bottomMargin={0}
                  topMargin={4}
                />
              )}
            </ShadowCard>
            <ButtonAuthorizedWrapper>
              <Button
                variant="containedLarge"
                color="primary"
                fullWidth
                type="submit"
                sx={{ overflow: "hidden" }}
              >
                {t("buttonTexts.submitButton")}
              </Button>
            </ButtonAuthorizedWrapper>
          </FormAuthorizedWrapper>
          {confirmationModalOpen && (
            <ConfirmationModal
              onFormSubmit={handleSubmit(async (data) => {
                setConfirmationModalOpen(false);
                navigate("/settings");
              })}
              title={t("requests.addOrUpdateRequest.confirmationModalTitle")}
              content={
                "Your question has been submitted successfully. We will get back to you as soon as possible."
              }
              btnTitle={t("buttonTexts.okButton")}
              hideClose={true}
            />
          )}
        </WrpperAuthorizedContainer>
      </Wrapper>
    </>
  );
};
